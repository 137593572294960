<template>
	<div id="rewards">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>德育</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">奖惩管理</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="nav-tab-bar-box u-f-item u-f-jsb">
				<div class="nav-tab-bar u-f-item">
					<div
						class="font_14 nav-tab-item"
						@click="twacherTap(index)"
						:class="index == teacherIndex ? 'active' : ''"
						v-for="(item, index) in teacherMeun"
						:key="index"
					>
						{{ item.name }}
					</div>
				</div>
				
			</div>
			<div class="content">
				<template v-if="teacherIndex==3">
					<div class="u-f">
						<div class="left">
							<div class="add u-f-justify el-icon-plus" @click="addtime = true"></div>
							<div class="building-list">
								<el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
								<el-checkbox-group v-model="building" @change="handleCheckedCitiesChange">
									<div v-for="items in placeList" :label="items.name" :key="items.id">
										<div class="u-f-item item u-f-jsb" @mouseover="items.isbtn = true" @mouseleave="items.isbtn = false">
											<div class="u-f-item">
												<el-checkbox :label="items.id" :key="items.id">
													<div>{{ items.name }}</div>
												</el-checkbox>
											</div>
											<div class="u-f-item btn" v-if="items.isbtn">
												<!-- <i class="el-icon-plus u-f-justify" @click.prevent @click.stop="editShow(items, 'add')"></i> -->
												<i class="el-icon-edit-outline u-f-justify" @click.prevent @click.stop="editShows(items)"></i>
												<i class="el-icon-delete u-f-justify" @click.prevent @click.stop="delPlace(items.id)"></i>
											</div>
										</div>
									</div>
								</el-checkbox-group>
							</div>
						</div>
						<div class=" right">
							<div class="title u-f-item u-f-jsb">
								<div>
									共{{ total1 }}条数据
								</div>
								<div class="u-f-item">
									<el-button size="small" @click="addroom=true">添加配置</el-button>
								</div>
							</div>
							<el-table
								ref="roomList"
								:data="configList"
								height="94%"
								style="width: 100%;border: 1px solid #EEEEEE;"
							>
								<el-table-column type="index" label="序号" width="150" align="center"></el-table-column>
								<el-table-column label="奖惩分类" column-key="name" :filter-method="filterHandler" :filters="filters" align="center">
									<template slot-scope="scope">
										<div v-if="scope.row.classify">{{scope.row.classify.name}}</div>
									</template>
								</el-table-column>
								<el-table-column prop="reason" label="奖惩名称" align="center"></el-table-column>
								<el-table-column label="奖惩类型" align="center">
									<template slot-scope="scope">
										<el-tag v-if="scope.row.type==1">正面</el-tag>
										<el-tag type="success" v-if="scope.row.type==2">负面</el-tag>
									</template>
								</el-table-column>
								<el-table-column prop="val" label="分数" align="center"></el-table-column>
								<el-table-column label="操作" width="150" align="center">
									<template slot-scope="scope">
										<el-button @click="edit_room(scope.row)" type="text" size="small">编辑</el-button>
										<el-button @click="del_room(scope.row.id)" type="text" size="small">删除</el-button>
									</template>
								</el-table-column>
							</el-table>
							<div class="u-f-right" style="padding: 15px 0;">
								<el-pagination
									@size-change="handleSizeChange1"
									@current-change="handleCurrentChange1"
									:current-page.sync="page1"
									:page-size="limit1"
									layout="total, prev, pager, next"
									:total="total1"
								></el-pagination>
							</div>
						</div>
					</div>
				</template>
			</div>
			
			<!-- 添加分类 -->
			<el-dialog title="添加分类" :visible.sync="addtime" width="500px" :before-close="handleClose">
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							类型
						</div>
						<el-radio-group v-model="classtype">
						    <el-radio :label="1">学生</el-radio>
						    <el-radio :label="2">班级</el-radio>
						    <el-radio :label="3">宿舍</el-radio>
						</el-radio-group>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							分类名称
						</div>
						<el-input v-model="placeName" placeholder="请输入分类名称" style="width: 75%;"></el-input>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="addtime = false" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="submit">确 定</el-button>
				</span>
			</el-dialog>
			<!-- 编辑分类 -->
			<el-dialog title="编辑分类" :visible.sync="editPlace" width="500px" :before-close="handleClose">
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							类型
						</div>
						<el-radio-group v-model="classtype">
						    <el-radio :label="1">学生</el-radio>
						    <el-radio :label="2">班级</el-radio>
						    <el-radio :label="3">宿舍</el-radio>
						</el-radio-group>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							分类名称
						</div>
						<el-input v-model="editPlaceName" placeholder="请输入分类名称" style="width: 75%;"></el-input>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="editPlace = false" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="submit1">确 定</el-button>
				</span>
			</el-dialog>
			<!-- 添加配置 -->
			<el-dialog title="添加配置" :visible.sync="addroom" width="500px" :before-close="handleClose">
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							奖惩分类
						</div>
						<el-select v-model="classify_id" placeholder="请选择奖惩分类"  style="width: 75%;" filterable >
							<el-option v-for="item in placeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							奖惩名称
						</div>
						<el-input v-model="reason" placeholder="请输入奖惩名称" style="width: 75%;"></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							评价性质
						</div>
						<el-radio-group v-model="type">
							<el-radio-button :label="1">正面</el-radio-button>
							<el-radio-button :label="2">负面</el-radio-button>
						</el-radio-group>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							分数
						</div>
						<el-input-number v-model="val" :max="30" ></el-input-number>
						
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							最大分数
						</div>
						<el-input-number v-model="max_val" :max="30" ></el-input-number>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="addroom = false" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="submitadd">确 定</el-button>
				</span>
			</el-dialog>
			<!-- 编辑配置 -->
			<el-dialog title="编辑配置" :visible.sync="editShow" width="500px" :before-close="handleClose">
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							奖惩分类
						</div>
						<el-select v-model="editclassify_id" placeholder="请选择奖惩分类"  style="width: 75%;" filterable >
							<el-option v-for="item in placeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							奖惩名称
						</div>
						<el-input v-model="editreason" placeholder="请输入奖惩名称" style="width: 75%;"></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							评价性质
						</div>
						<el-radio-group v-model="edittype">
							<el-radio-button :label="1">正面</el-radio-button>
							<el-radio-button :label="2">负面</el-radio-button>
						</el-radio-group>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							分数
						</div>
						<el-input-number v-model="editval" :max="30" ></el-input-number>
						
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							最大分数
						</div>
						<el-input-number v-model="editmax_val" :max="30" ></el-input-number>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="editShow = false" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="submitedit">确 定</el-button>
				</span>
			</el-dialog>
		</index>
	</div>
</template>

<script>
import index from '@/components/common/index.vue';
export default {
	components: {
		index
	},
	data() {
		return {
			teacherMeun: [
				{ name: '学生奖惩',id:1},
				{ name: '班级奖惩',id:2},
				{ name: '宿舍奖惩',id:3},
				{ name: '奖惩配置',id:4}
			],
			teacherIndex: 3,
			page:1,
			limit:12,
			total:0,
			list:[],
			addtime: false,
			checkAll: false,
			isIndeterminate: true,
			building: [],
			placeList: [],
			filters:[],
			placeName:'',
			addroom:false,
			classify_id:'',
			val:0,
			type:1,
			reason:'',
			configList:[],
			page1:1,
			limit1:12,
			total1:0,
			editrewardid:'',
			editPlaceName:'',
			editPlace:false,
			editid:'',
			editreason:'',
			editclassify_id:'',
			editval:'',
			edittype:'',
			editShow:false,
			classtype:1,
			max_val:0,
			editmax_val:0
		};
	},
	mounted() {
		this.getPlaceList()
		this.getconfigList()
	},
	methods: {
		edit_room(item){
			this.editid = item.id;
			this.editreason = item.reason;
			this.editclassify_id = item.classify_id;
			this.editval = item.val;
			this.edittype = item.type;
			this.editmax_val = item.max_val;
			this.editShow = true;
		},
		// 宿舍楼编辑
		editShows(item) {
			this.editPlaceName = item.name;
			this.editrewardid = item.id;
			this.classtype = item.type;
			this.editPlace = true;
		},
		filterHandler(value, row, column) {
			const property = column['property'];
			return row[property] === value;
		},
		handleCheckAllChange(val) {
			let arr = [];
			for(let i in this.placeList){
				arr.push(this.placeList[i].id)
			}
			this.building = val ? arr : [];
			this.isIndeterminate = false;
			this.getconfigList()
		},
		handleCheckedCitiesChange(e) {
			console.log(this.building);
			this.getconfigList()
		},
		// 获取分类列表
		getPlaceList() {
			this.$api.setting.rewardsTypeIndex({}).then(res => {
				if (res.data.code == 1) {
					let data = res.data.data.rows;
					for (let i in data) {
						data[i].isbtn = false;
					}
					this.placeList = data;
					let arr = [];
					for (let i in this.placeList) {
						arr.push({
							text: this.placeList[i].name,
							value: this.placeList[i].name
						});
					}
					this.filters = arr;
				}
			});
		},
		handleClose(done) {
			done();
			// this.initialize();
			// this.info()
		},
		// 添加分类
		submit() {
			this.$api.setting.addRewardsType({ name: this.placeName,type:this.classtype}).then(res => {
				if (res.data.code == 1) {
					this.$message.success('添加成功');
					this.placeName = '';
					this.classtype = 1;
					this.addtime = false;
					this.getPlaceList();
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		submit1(){
			this.$api.setting.editRewardsType({ 
				id:this.editrewardid,
				name: this.editPlaceName,
				type:this.classtype
			}).then(res => {
				if (res.data.code == 1) {
					this.$message.success('编辑成功');
					this.editPlaceName = '';
					this.classtype = 1;
					this.editrewardid = false;
					this.editPlace = false;
					this.getPlaceList();
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		submitadd(){
			this.$api.setting.addRewardsConfig({ 
				reason:this.reason,
				classify_id:this.classify_id,
				type:this.type,
				val:this.val,
				max_val:this.max_val
			}).then(res => {
				if (res.data.code == 1) {
					this.$message.success('添加成功');
					this.reason = '';
					this.classify_id = '';
					this.type = 1;
					this.val = 0;
					this.addroom = false;
					this.getconfigList();
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		submitedit(){
			this.$api.setting.editRewardsConfig({
				id:this.editid,
				reason:this.editreason,
				classify_id:this.editclassify_id,
				type:this.edittype,
				val:this.editval,
				max_val:this.editmax_val
			}).then(res => {
				if (res.data.code == 1) {
					this.$message.success('编辑成功');
					this.editreason = '';
					this.editclassify_id = '';
					this.edittype = 1;
					this.editval = 0;
					this.editmax_val = 0;
					this.editShow = false;
					this.getconfigList();
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		getconfigList(){
			let data = {
				page:this.page1,
				limit:this.limit1
			}
			if(this.building.length){
				data.filter = JSON.stringify({'classify_id':this.building.toString()});
				data.op = {'classify_id':'in'}
			}
			this.$api.setting.RewardsConfigIndex(data).then(res=>{
				if(res.data.code==1){
					this.configList = res.data.data.rows;
					this.total1 = res.data.data.total;
				}
			})
		},
		// 删除分类
		delPlace(id) {
			this.$confirm('确定要删除？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消'
			}).then(() => {
				this.$api.setting
					.delRewardsType({
						id: id
					})
					.then(res => {
						if (res.data.code == 1) {
							this.$message.success('删除成功');
							this.getPlaceList();
						} else {
							this.$message.error(res.data.msg);
						}
					});
			});
		},
		del_room(id){
			this.$confirm('确定要删除？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消'
			}).then(() => {
				this.$api.setting
					.delRewardsConfig({
						id: id
					})
					.then(res => {
						if (res.data.code == 1) {
							this.$message.success('删除成功');
							this.getconfigList();
						} else {
							this.$message.error(res.data.msg);
						}
					});
			});
		},
		twacherTap(index){
			if(index==0){
				this.$router.push('/studentrewards')
			}else if(index==1){
				this.$router.push('/classRewards')
			}else if(index==2){
				this.$router.push('/dormrewards')
			}else if(index==3){
				this.$router.push('/rewards')
			}
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			this.page = val;
			this.rewardLists()
		},
		handleSizeChange1(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange1(val) {
			this.page1 = val;
			this.getconfigList()
		},
		rewardLists(){
			this.$api.setting.rewardLists({
				page:this.page,
				limit:this.limit,
				filter:JSON.stringify({'type':this.teacherMeun[this.teacherIndex].id})
			}).then(res=>{
				if(res.data.code==1){
					this.list = res.data.data.rows;
					this.total = res.data.data.total;
				}
			})
		},
		rewardsImport(){
			this.$api.setting.rewardsImport({
				type:this.teacherMeun[this.teacherIndex].id
			}).then(res=>{
				if(res.data.code==1){
					location.href = res.data.data.url;
				}
			})
		}
	}
};
</script>

<style lang="scss">
#rewards {
	.nav-tab-bar-box {
		background-color: #ffffff;
		padding: 15px;
		margin-top: 1.04vw;
		.nav-tab-bar {
			.nav-tab-item {
				color: #6e7a87;
				padding: 0 0.78125vw;
				border-right: 1px solid #f3f3f3;
				cursor: pointer;
				-webkit-touch-callout: none;
				-webkit-user-select: none;
				-khtml-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
			}
			&div:last-child {
				border-right: none;
			}
			.active {
				font-size: 0.833vw;
				color: #63acff;
				position: relative;
				font-weight: bold;
			}
			.active::after {
				position: absolute;
				content: '';
				height: 2px;
				left: 0.78125vw;
				right: 0.78125vw;
				background-color: #63acff;
				bottom: -0.78125vw;
			}
		}
	}
	.head {
		background-color: #ffffff;
		padding: 10px 30px;
		margin-top: 15px;
		.span {
			margin-right: 10px;
			background-color: #f9f9f9;
			color: #8d9da6;
			padding: 3px 10px;
		}
	}
	.content {
		margin-top: 20px;
		background-color: #ffffff;
		height: 78vh;
		.left {
			border-right: 1px solid #eeeeee;
			height: 78vh;
			min-width: 300px;
			.add {
				background-color: #f8f8f8;
				padding: 10px 0;
				font-size: 24px;
				color: #8d9da6;
				cursor: pointer;
			}
			.add:hover {
				color: #007aff;
			}
			.building-list {
				padding: 25px;
				height: 68vh;
				overflow-y: auto;
				.item {
					padding: 0 15px;
					height: 40px;
					line-height: 40px;
					margin-top: 15px;
					background-color: #f8f8f8;
					.el-icon-caret-right,
					.el-icon-caret-bottom {
						color: #a4a4a4;
						margin-right: 5px;
						font-size: 16px;
					}
					.el-checkbox {
						display: block;
					}
					.btn {
						i {
							background-color: #e8eaec;
							color: #a4a4a4;
							width: 25px;
							height: 25px;
							margin-left: 10px;
							border-radius: 50%;
						}
						i:hover {
							color: red;
						}
					}
				}
				.floor-list {
					background-color: #f8f8f8;
					border-top: 1px solid #eeeeee;
					padding: 0 15px 15px 30px;
					.floor-item {
						background-color: #f1f1f1;
						padding: 0 15px 0 25px;
						color: #4c5559;
						height: 40px;
						line-height: 40px;
						margin-top: 15px;
						.btn {
							i {
								background-color: #ffffff;
								color: #a4a4a4;
								width: 25px;
								height: 25px;
								margin-left: 10px;
								border-radius: 50%;
							}
							i:hover {
								color: red;
							}
						}
					}
					.active {
						background-color: #e4f1ff;
						color: #007aff;
					}
				}
			}
		}
		.right {
			height: 68vh;
			width: 100%;
			padding: 25px 50px;
			.title {
				padding: 0 0 15px 0;
			}
		}
		.suguan{
			padding: 15px 50px 15px 50px;
			height: 70vh;
			.title {
				padding: 0 0 15px 0;
			}
		}
	}

	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0 20px 35px 20px;
		.edit-box {
			.input-box {
				margin-top: 35px;
				padding-left: 15px;
				.input-lebal {
					color: #aeb5b8;
					margin-right: 10px;
					span {
						color: #ff7a65;
						margin-right: 3px;
					}
				}
				.el-icon-remove-outline {
					font-size: 1.5625vw;
					color: #d7d9db;
					margin-left: 10px;
				}
				.input-item{
					.el-select .el-input.is-disabled .el-input__inner {
						cursor: default;
					}
					.el-input.is-disabled .el-input__inner {
						background-color: #ffffff;
						cursor: default;
					}
					.el-input__prefix,
					.el-input__suffix {
						display: none;
					}
				}
			}
			.avatar-uploader .el-upload {
				border: 1px dashed #d9d9d9;
				border-radius: 6px;
				cursor: pointer;
				position: relative;
				overflow: hidden;
			}
			.avatar-uploader .el-upload:hover {
				border-color: #409eff;
			}
			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				width: 90px;
				height: 90px;
				line-height: 90px;
				text-align: center;
			}
			.avatar {
				width: 90px;
				height: 90px;
				display: block;
			}
		}
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}

	.el-table__column-filter-trigger {
		.el-icon-arrow-down:before {
			content: '\E790';
			font-size: 16px;
		}
	}
}

.selectShow {
	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0;
		.user-list-box {
			.user-list-head {
				padding: 10px 20px;
				border-bottom: 1px solid #eeeeee;
				.user-type-list {
					.user-type-item {
						color: #8d9da6;
						cursor: pointer;
					}
					.active {
						color: #007aff;
						position: relative;
					}
					.active::after {
						position: absolute;
						width: 25px;
						height: 2px;
						background-color: #007aff;
						content: '';
						bottom: -5px;
						left: 0;
					}
					span {
						color: #cccccc;
						margin: 0 8px;
					}
				}
			}
			.user-list-content {
				height: 40vh;
				.left {
					height: 98%;
					overflow-y: auto;
					border-right: 1px solid #eeeeee;
					padding-top: 5px;
					.left-item {
						padding: 0 20px;
						height: 40px;
						line-height: 40px;
						color: #a4a4a4;
						cursor: pointer;
					}
					.active {
						background-color: #e4f1ff;
						color: #2d8cf0;
						position: relative;
					}
					.active:after {
						position: absolute;
						top: 0;
						bottom: 0;
						right: 0;
						width: 2px;
						content: '';
						background-color: #2d8cf0;
					}
				}
				.right {
					height: 37vh;
					padding: 10px 25px;
					overflow-y: auto;
					.list {
						display: flex;
						flex-wrap: wrap;
						.item {
							cursor: pointer;
							padding: 5px 15px;
							border-radius: 3px;
							background-color: #f3f3f3;
							margin-right: 10px;
							margin-bottom: 10px;
						}
						.active {
							background-color: #e4f1ff;
							color: #2d8cf0;
						}
					}
				}
			}
		}
	}
	.dialog-title {
		padding: 10px 0 5px 0;
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
}
.el_option {
	display: none !important;
}
</style>
